import React from "react"

import Layout from "../components/Layout"

import SliderSection from "../components/SliderSection"

import Slide from "../components/Slide"

import slideImage0 from "./../../slike/slide/slide0.jpg";

export default function ErrorPage() {
  return (
	<Layout title="GEO KONTURA d.o.o.">
		<SliderSection>
			<Slide backgroundImage={slideImage0} >
				<h3 className="kn-slide-title">Greška 404</h3>
			</Slide>
		</SliderSection>
	</Layout>
  )
}
